import '../../BootstrapTheme/assets/js/index';

// Main styles file
import './scss/index.scss';

// import '../../BootstrapTheme/assets/index';

// Images
import './media/ebc-logo.jpg';
import './media/favicon.ico';

// // Main styles file
// import './scss/index.scss';

// import '../../BootstrapTheme/assets/media/sylius-logo.png';
// import '../../BootstrapTheme/assets/js/fontawesome';

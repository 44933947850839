const SyliusTyreSearch = () => {
  const form = document.querySelector('form[name="tyre_search"]');
  if (form) {
    const tyreSizeSelect = form.querySelector('#tyre_search_tyre_size');
    const widthSelect = form.querySelector('#tyre_search_width');
    const heightSelect = form.querySelector('#tyre_search_height');
    const inchesSelect = form.querySelector('#tyre_search_inches');

    if (tyreSizeSelect && widthSelect && heightSelect && inchesSelect) {
      tyreSizeSelect.addEventListener('change', function (event) {
        const tyreSizeSelectValue = event.target.value;

        const match = tyreSizeSelectValue.match(/(\d{3})(\d{2})[rR](\d{2})/);

        if (match) {
          const selectWidth = match[1] ?? null;
          const selectHeight = match[2] ?? null;
          const selectInches = match[3] ?? null;

          if (selectWidth) {
            widthSelect.value = selectWidth;
          }

          if (selectHeight) {
            heightSelect.value = selectHeight;
          }

          if (selectInches) {
            inchesSelect.value = selectInches;
          }
        }
      });
    }
  }
};

export default SyliusTyreSearch;

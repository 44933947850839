const SyliusFitments = () => {

  const fitmentsaccordion = document.getElementById('fitmentsaccordion');

  fitmentsaccordion.addEventListener('shown.bs.collapse', function (e) {
      const _el = e.target;
      const ajaxUrl = _el.getAttribute('data-ajax-url');
  
      if (!_el.getAttribute('data-fitments-loaded') && ajaxUrl) {
          fetch(ajaxUrl)
              .then(response => response.text())
              .then(data => {
                  _el.innerHTML = data;
                  _el.setAttribute('data-fitments-loaded', 'true');
              })
              .catch(error => console.error('Error fetching data:', error));
      }
  });


};
  
export default SyliusFitments;
  